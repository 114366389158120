<template lang="pug">
  .home-page
    section#steps1.hero
      HomeBanner

    section#steps2.conversion
      ConversionBlock
      BenefitsBlock

    section#steps3.features
      FeaturesBlock
      DesignBlock
      AppBlock

    section#steps4.price
      .price__container
        h3.title.price__title КОМПЛЕКТИ ПОСТАВКИ DRIVELINK
        PriceBlock

    section.additional-services
      h3.title.additional-services__title
        | ДОДАТКОВІ ПОСЛУГИ
      div.additional-services__container
        div.additional-services-swiper.additional-services__slider.swiper
          div.swiper-wrapper
            div.swiper-slide.additional-services__item
              h4 ПЕРЕОБЛАДНАННЯ РОУТЕРУ STARLINK
              div.additional-services__cost
                | 120
                span $
              p Швидке та якісне переобладнання вашого роутера від Starlink під 12V живлення.
              router-link.link.additional-services__link(to="" v-scroll-to="'#steps5'") Замовити
            div.swiper-slide.additional-services__item
              h4 РЕМОНТ STARLINK
              div.additional-services__cost
                span ВІД
                | 65
                span $
              p Професійне обслуговування та ремонт вашого обладнання Starlink.
              router-link.link.additional-services__link(to="" v-scroll-to="'#steps5'") Замовити
            div.swiper-slide.additional-services__item.additional-services__item--disabled
              h4 ЗАМІНА РОЗ'ЄМІВ НА RJ45
              div.additional-services__cost
                span ВІД
                | 70
                span $
              p Заміна стандартних роз'ємів вашого обладнання Starlink на універсальні RJ45.
              router-link.link.additional-services__link(to="" v-scroll-to="'#steps5'") Замовити
            div.swiper-slide.additional-services__item
              h4 ІНДИВІДУАЛЬНІ РІШЕННЯ
              div.additional-services__cost
                span ВІД
                | 60
                span $
              p Адаптація обладнання Starlink до ваших унікальних потреб.
              router-link.link.additional-services__link(to="" v-scroll-to="'#steps5'") Замовити

        div.swiper-button-prev
        div.swiper-pagination
        div.swiper-button-next
    section#steps5.contacts
      .contacts__container
        .contacts__img
          img(src="~@/assets/images/contacts-img.jpg" alt="solider")
        .contacts__content
          h3.title.contacts__title
            | Залишити заявку
          form.contacts__form(action="/" method="POST")
            label.contacts__label(for="full_name")
              span Ім'я
              input.contacts__input(type="text" name="name" required placeholder="Ваше ім'я" v-model="full_name" @input="validateName")
              .error(v-if="nameTouched && !nameIsValid")
                span Введіть коректне ім'я
            label.contacts__label(for="phone_number")
              span Номер телефону
              input.contacts__input(type="tel" name="phone_number" required placeholder="Номер телефону для зв'язку" v-model="phone_number" @focus="handlePhoneFocus" @input="validatePhone" @blur="handlePhoneBlur")
              .error(v-if="phoneTouched && !phoneIsValid")
                span(v-if="phone_number.length < 13") Занадто мало символів
                span(v-else) Занадто багато символів
            label.contacts__label(for="email")
              span Email
              input.contacts__input(type="email" name="email" required placeholder="Адреса електронної пошти для зв'язку" v-model="email" @input="validateEmail")
              .error(v-if="emailTouched && !emailIsValid")
                span Введіть коректну електронну адресу
            label.contacts__label(for="additional_info")
              span Напишіть, що Вас цікавить
              input.contacts__input(type="text" name="additional_info" required placeholder="Ваше повідомлення" v-model="additional_info" @input="validateMessage")
              .error(v-if="messageTouched && !messageIsValid")
                span Введіть повідомлення
            button.btn.contacts__button(type="submit" @click.prevent="submit") Відправити
    .popup(v-show="isShowPopup" @closePopup="isShowPopup = false" @click="removePopup")
      .popup__wrapper
        .popup__close(@click="removePopup")
          img(src="~@/assets/images/popupclose.svg" alt="закрити")
        .popup__title Заявку надіслано!
        .popup__text Дякуємо за Ваше звернення! Наші фахівці звʼяжуться з Вами якомога швидше.
    a.top(href="#" :class="{ active: isScrolled }" @click.prevent="scrollToTop")
      svg(width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg")
        path(d="M8 15V1M8 1L1 8M8 1L15 8" stroke="#101828" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
</template>

<script>
import Swiper, { Navigation, Pagination } from 'swiper'
import 'swiper/swiper-bundle.min.css'
export default {
  name: 'HomePage',
  components: {
    HomeBanner: () => import('@/components/HomeBanner'),
    ConversionBlock: () => import('@/components/ConversionBlock'),
    BenefitsBlock: () => import('@/components/BenefitsBlock'),
    FeaturesBlock: () => import('@/components/FeaturesBlock'),
    DesignBlock: () => import('@/components/DesignBlock'),
    AppBlock: () => import('@/components/AppBlock'),
    PriceBlock: () => import('@/components/PriceBlock')
  },
  data () {
    return {
      full_name: '',
      phone_number: '',
      email: '',
      additional_info: '',
      nameTouched: false,
      phoneTouched: false,
      emailTouched: false,
      messageTouched: false,
      phoneIsValid: false,
      nameIsValid: false,
      emailIsValid: false,
      messageIsValid: false,
      isScrolled: false,
      isShowPopup: false
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
    new Swiper('.additional-services-swiper', {
      modules: [Navigation, Pagination],
      slidesPerView: 3,
      slidesPerGroup: 1,
      spaceBetween: 24,
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      breakpoints: {
        250: {
          slidesPerView: 1
        },
        550: {
          slidesPerView: 2
        },
        992: {
          slidesPerView: 3
        }
      }
    })
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    validateName () {
      const regex = /^[a-zA-Zа-яА-ЯіІїЇєЄґҐ'` ]+$/
      this.nameIsValid = regex.test(this.full_name)
    },
    validatePhone () {
      const regex = /^\+380\d{9}$/
      this.phoneIsValid = regex.test(this.phone_number)
    },
    handlePhoneFocus () {
      if (this.phone_number === '') {
        this.phone_number = '+380'
        this.validatePhone()
      }
    },
    handlePhoneBlur () {
      if (this.phone_number === '+380') {
        this.phone_number = ''
      }
      this.validatePhone()
    },
    validateEmail () {
      const regex = /^[^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*@[^<>()[\]\\.,;:\s@"]+\.([a-zA-Z]{2,})$/
      this.emailIsValid = regex.test(this.email)
    },
    validateMessage () {
      const regex = /^[a-zA-Zа-яА-ЯіІїЇєЄґҐ'` 0-9?!().,"']+$/
      this.messageIsValid = regex.test(this.additional_info)
    },
    validateForm () {
      if (this.full_name.length === 0 && this.phone_number.length === 0 && this.email.length === 0 && this.additional_info.length === 0) {
        this.nameIsValid = false
        this.phoneIsValid = false
        this.emailIsValid = false
        this.messageIsValid = false
      }
    },
    submit () {
      this.nameTouched = true
      this.phoneTouched = true
      this.emailTouched = true
      this.messageTouched = true
      this.validateForm()
      if (this.nameIsValid && this.phoneIsValid && this.emailIsValid && this.messageIsValid) {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            full_name: this.full_name,
            email: this.email,
            phone_number: this.phone_number,
            additional_info: this.additional_info
          })
        }
        fetch(`${ process.env.VUE_APP_API }/api/v1/contact_me_form/`, requestOptions)
          .then(response => {
            if (response?.status === 200) {
              this.isShowPopup = true
              this.waitPopup()
              this.full_name = ''
              this.phone_number = ''
              this.email = ''
              this.additional_info = ''
              this.phoneTouched = false
              this.nameTouched = false
              this.emailTouched = false
              this.messageTouched = false
              this.phoneIsValid = false
              this.nameIsValid = false
              this.emailIsValid = false
              this.messageIsValid = false
            }
          })
      }
    },
    removePopup () {
      this.isShowPopup = false
    },
    waitPopup () {
      setTimeout(() => {
        this.isShowPopup = false
      }, 4000)
    },
    handleScroll () {
      this.isScrolled = window.scrollY > 100
    },
    scrollToTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>
